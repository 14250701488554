import { useTranslation } from "next-i18next";
import { title } from "process";

export const isProd =
  process.env.NODE_ENV === "production" &&
  !process.env.NEXT_APP_API_HOST.includes("-test.");

// App 6
export const AppType = process.env.NEXT_APP_TENANTID;

export const isDrFans = "16" == AppType;

// name
const getAppName = () => {
  switch (AppType) {
    case "16":
      return "erasa";
    default:
      return "";
  }
};

const appTitle = `Erasa｜Remove Leaked Content Now! Stop Fakes and Protect Your Content`;
const appDesc = `OnlyFans Content leaked?   Erasa helps you quickly detect and remove leaked content, wipe out the fakes, and protect your creations and followers! Act now and keep your unique content safe!`;
export const lowerName = getAppName().replace(/ /g, "_").toLowerCase();

// app logo
const getAppLogo = `/images/layouts/${lowerName}-logo.png`;
// app net logo
const getAppNetLogo = `/images/layouts/${lowerName}-logo.png`;
// app name
const getNamePath = `/images/layouts/${lowerName}.png`;
// app ico
const getAppICO = `/${lowerName}_favicon.ico`;

// const getWebHost = `https://www.dolphinradar.com`;
// const getBlogHost = `https://www.dolphinradar.com`;

export const currentHost = () => {
  if (typeof window === "undefined") {
    return "";
  }

  if (window.location.host.includes("localhost")) {
    return `localhost:3000`;
  } else if (window.location.host.includes("-test")) {
    return `www-test.erasa.ai`;
  } else {
    return `www.erasa.ai`;
  }
};

const getWebHost = () => {
  if (typeof window === "undefined") {
    return "";
  }

  if (window.location.host.includes("-test.")) {
    return `https://www-test.erasa.ai`;
  } else {
    return `https://www.erasa.ai`;
  }
};

//
const getFirebaseConfig = () => {
  if (isProd) {
    return {
      apiKey: "AIzaSyBxxNm-z2lMXuYQuBG3vU811nS4ta5xqi8",
      authDomain: `${currentHost()}/fb-auth`,
      projectId: "erasa-prod",
      storageBucket: "erasa-prod.appspot.com",
      messagingSenderId: "4258911547",
      appId: "1:4258911547:web:ba126452ed9b05c16a531e",
      measurementId: "G-DY5GP8XTSE",
    };
  } else {
    return {
      apiKey: "AIzaSyARm3ZoXb8-du6oLLKIbApwwZexJr65ZPM",
      authDomain: `${currentHost()}/fb-auth`,
      projectId: "erasa-test",
      storageBucket: "erasa-test.appspot.com",
      messagingSenderId: "331852006039",
      appId: "1:331852006039:web:c2efb276b9cd52bd355fd2",
      measurementId: "G-KPSMTLJ8LF",
    };
  }
};

interface AppSettings {
  type: number;
  name: string;
  logoUrl: string;
  logoPath: string;
  namePath: string;
  icoPath: string;
  title: string;
  headSubTitle: string;
  desc: string;
  firebaseConfig: {};
  navItems: [];
  webHost: string;
  clarityId: string;
}

export const AppSettings = {
  type: AppType,
  name: getAppName(),
  logoUrl: getAppNetLogo,
  logoPath: getAppLogo,
  icoPath: getAppICO,
  namePath: getNamePath,
  title: appTitle,
  desc: appDesc,
  firebaseConfig: getFirebaseConfig(),
  webHost: getWebHost(),
  clarityId: isProd ? `nqmqitt2vl` : "=",
};
