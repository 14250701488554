import axios from "@/shared/axios";
import { BaseResponse } from "@/types/web.interface";

export async function getUserInfoApi() {
  const res = await axios.get<BaseResponse<any>>(`/user/info`);
  return res.data;
}
// 用户个人产品订阅信息
export async function getPersionalProductApi() {
  const res = await axios.get<BaseResponse<any>>(`/product/list/userInfo`);
  return res.data;
}

// 消费记录
export async function getPaymentListApi(params: any) {
  const res = await axios.get<BaseResponse<any>>(`/stripe/order/list`, {
    params,
  });
  return res.data;
}
//订阅使用量
export async function getUserUseTimesApi() {
  const res = await axios.get<BaseResponse<any>>(`/user/usage`);
  return res.data;
}

// 消费用量

export async function getUserSpendApi(params: {
  page_no: number;
  page_size: number;
}) {
  const res = await axios.get<BaseResponse<any>>(`/user/consumePage`, {
    params,
  });
  return res.data;
}

// 修改用户信息

export async function postEditUserInfoApi(params: any) {
  const res = await axios.post<BaseResponse<any>>(`/user/update/info`, params);
  return res.data;
}

// 升级订阅

export async function upgradeSubApi(params: any) {
  const res = await axios.post<BaseResponse<any>>(`/product/upgrade`, params);
  return res.data;
}

// 退订
export async function cancelSubApi(params: any) {
  const res = await axios.post<BaseResponse<any>>(`/product/cancel`, params);
  return res.data;
}
// 恢复订阅
export async function resStartMonitorInfoApi() {
  const res = await axios.post<BaseResponse<any>>(`/product/resume`);
  return res.data;
}
